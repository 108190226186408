@font-face {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 300;
  src: local("Futura PT Book"),
    url("./assets/fonts/FuturaCyrillicBook.woff") format("woff");
}

@font-face {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 400;
  src: local("Futura PT Light"),
    url("./assets/fonts/FuturaCyrillicLight.woff") format("woff");
}

@font-face {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 500;
  src: local("Futura PT Medium"),
    url("./assets/fonts/FuturaCyrillicMedium.woff") format("woff");
}

@font-face {
  font-family: "Futura PT Demi";
  font-style: normal;
  font-weight: 600;
  src: local("Futura PT"),
    url("./assets/fonts/FuturaCyrillicDemi.woff") format("woff");
}

@font-face {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 700;
  src: local("Futura PT Heavy"),
    url("./assets/fonts/FuturaCyrillicHeavy.woff") format("woff");
}

@font-face {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 800;
  src: local("Futura PT Bold"),
    url("./assets/fonts/FuturaCyrillicBold.woff") format("woff");
}

@font-face {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 900;
  src: local("Futura PT Extra Bold"),
    url("./assets/fonts/FuturaCyrillicExtraBold.woff") format("woff");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html {
  scroll-behavior: smooth;
}
.container {
  max-width: 1460px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}

.slick-slide > div > div {
  display: flex !important;
}

.slick-list > .slick-track {
  display: flex;
  gap: 20px !important;
}
